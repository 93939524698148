import React from 'react';
import loadable from '@loadable/component';

import Layout from 'components/shared/Layout';
import SEO from 'components/shared/Seo';

const BannerIndustry = loadable(() =>
  import('../components/organisms/BannerIndustry')
);
const DescriptionIndustry = loadable(() =>
  import('../components/organisms/DescriptionIndustry')
);

const IndustryTemplate = ({ data }) => {
  const {
    allWordpressWpAppIndustry2,
    allWordpressWpAppWorkTag,
    allWordpressWpAppTestimonial,
  } = data;

  const tags = allWordpressWpAppWorkTag.edges;

  const currentPageData = allWordpressWpAppIndustry2.edges[0].node;
  const currentPageWidgetsData =
    currentPageData.acf?.blocks_app_industry_2 || [];
  const breadcrumbsData = {
    data: {
      title: currentPageData.title,
      path: currentPageData.path,
    },
    parent: { title: 'Industries', path: '/industries' },
  };

  const testimonialsPageData = allWordpressWpAppTestimonial.nodes;

  const pageLink = currentPageData.link;

  let pageComponents = [];

  currentPageWidgetsData.forEach((widget, index) => {
    const spacer = currentPageWidgetsData[index + 1]
      ? currentPageWidgetsData[index + 1].__typename ===
          'WordPressAcf_spacer' && true
      : false;

    const blockMargin =
      (widget.__typename === 'WordPressAcf_cta' ||
        widget.__typename === 'WordPressAcf_testimonials') &&
      index + 1 !== currentPageWidgetsData.length &&
      !spacer;

    switch (widget.__typename) {
      case 'WordPressAcf_cta':
        const CallToAction = loadable(() =>
          import('../components/organisms/CallToAction')
        );
        return pageComponents.push(
          <CallToAction data={widget} key={index} customMargin={blockMargin} />
        );

      case 'WordPressAcf_logos':
        const LogosIndustry = loadable(() =>
          import('../components/organisms/LogosIndustry')
        );
        return pageComponents.push(
          <LogosIndustry data={widget} key={index} spacer={spacer} />
        );

      case 'WordPressAcf_bullet_list':
        const BulletListIndustry = loadable(() =>
          import('../components/organisms/BulletListIndustry')
        );
        return pageComponents.push(
          <BulletListIndustry data={widget} key={index} spacer={spacer} />
        );

      case 'WordPressAcf_text_icons':
        const TextIconsIndustry = loadable(() =>
          import('../components/organisms/TextIconsIndustry')
        );
        return pageComponents.push(
          <TextIconsIndustry data={widget} key={index} spacer={spacer} />
        );

      case 'WordPressAcf_spacer':
        const Spacer = loadable(() => import('../components/organisms/Spacer'));
        return pageComponents.push(<Spacer data={widget} key={index} />);

      case 'WordPressAcf_gallery':
        const Gallery = loadable(() =>
          import('../components/organisms/Gallery')
        );
        return pageComponents.push(
          <Gallery
            data={widget}
            key={index}
            spacer={spacer}
            caseStudy={false}
          />
        );

      case 'WordPressAcf_text':
        const TextCaseStudy = loadable(() =>
          import('../components/organisms/TextCaseStudy')
        );
        return pageComponents.push(
          <TextCaseStudy data={widget} key={index} spacer={spacer} />
        );

      case 'WordPressAcf_text_large':
        const TitleIndustry = loadable(() =>
          import('../components/organisms/TitleIndustry')
        );
        return pageComponents.push(
          <TitleIndustry data={widget} key={index} spacer={spacer} />
        );

      case 'WordPressAcf_Features':
        const FeaturesCaseStudy = loadable(() =>
          import('../components/organisms/FeaturesCaseStudy')
        );
        return pageComponents.push(
          <FeaturesCaseStudy data={widget} key={index} />
        );

      case 'WordPressAcf_case_study_text':
        const TextCaseStudyNew = loadable(() =>
          import('../components/organisms/TextCaseStudyNew')
        );
        return pageComponents.push(
          <TextCaseStudyNew data={widget} key={index} />
        );

      case 'WordPressAcf_highlighted_text':
      case 'WordPressAcf_quote':
        const HighlightedQuoteText = loadable(() =>
          import('../components/organisms/HighlightedQuoteText')
        );
        return pageComponents.push(
          <HighlightedQuoteText data={widget} key={index} />
        );

      case 'WordPressAcf_case_studies_featured':
        const RelatedCases = loadable(() =>
          import('../components/organisms/RelatedCases')
        );
        return pageComponents.push(
          <RelatedCases
            data={widget}
            key={index}
            pageLink={pageLink}
            caseStudy={false}
          />
        );

      case 'WordPressAcf_testimonials':
        const Testimonials = loadable(() =>
          import('../components/organisms/Testimonials')
        );
        return pageComponents.push(
          <Testimonials
            data={widget}
            testimonialsData={testimonialsPageData}
            key={index}
            customMargin={blockMargin}
          />
        );

      case 'WordPressAcf_workflow':
        const Workflow = loadable(() =>
          import('../components/organisms/Workflow')
        );
        return pageComponents.push(
          <Workflow data={widget} key={index} industry={true} spacer={spacer} />
        );
      default:
        return;
    }
  });

  return (
    <Layout isInverted invertFooter footerType={'full'}>
      <SEO yoastMeta={currentPageData.yoast_meta} url={pageLink} />
      <BannerIndustry
        data={currentPageData}
        breadcrumbsData={breadcrumbsData}
      />
      <DescriptionIndustry
        data={currentPageData}
        tagsData={tags}
        isSpacer={
          currentPageWidgetsData[0].__typename === 'WordPressAcf_spacer'
        }
        servicesHeader="Related Services"
      />
      {pageComponents}
    </Layout>
  );
};

export default IndustryTemplate;

export const query = graphql`
  query($id: String!) {
    allWordpressWpAppIndustry2(filter: { id: { eq: $id } }) {
      ...AllWordpressWpAppIndustry2Fragment
    }
    allWordpressWpAppWorkTag {
      ...AllWordpressWpAppWorkTagFragment
    }
    allWordpressWpAppTestimonial {
      ...AllWordpressWpAppTestimonialFragment
    }
  }
`;
